<template>
  <page>
    <template slot="headerInfo">
      <el-button
        v-auth="'supplierGoods:upload'"
        @click="visible = true"
        type="primary"
      >
        导入
      </el-button>
      <el-button
        v-auth="'supplierGoods:add'"
        @click="
          addVisible = true
          currentItem = null
        "
        type="primary"
      >
        添加商品
      </el-button>
      <el-button
        v-auth="'supplierGoods:batchDelete'"
        @click="delHandler(false)"
        :disabled="!multipleSelection.length"
        type="danger"
      >
        批量删除
      </el-button>
    </template>

    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="商品名称" prop="goodsName">
          <el-input placeholder="商品名称" v-model="params.goodsName" />
        </el-form-item>

        <el-form-item label="品牌" prop="goodsBrand">
          <el-select v-model="params.goodsBrand" clearable filterable>
            <el-option
              v-for="item in brandList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品状态" prop="state">
          <el-select v-model="params.state" clearable>
            <el-option
              v-for="item in stateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品一级分类" prop="clazzId1">
          <el-select
            v-model="params.clazzId1"
            @change="typeOneChange"
            clearable
          >
            <el-option
              v-for="item in typeList1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="仓库归属" prop="groupId">
          <el-select v-model="params.groupId" clearable>
            <el-option
              v-for="item in groupList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="商品二级分类" prop="clazzId2">
          <el-select v-model="params.clazzId2" clearable>
            <el-option
              v-for="item in typeList2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->

        <!-- <el-form-item label="一件代发" prop="state">
          <el-select v-model="params.oneForDistribution" clearable>
            <el-option
              v-for="item in distributionList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        height="100%"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          show-overflow-tooltip
          prop="goodsName"
          label="商品名称"
        />
        <el-table-column prop="goodsImg" label="商品图片">
          <template #default="{ row }">
            <el-popover
              v-if="row.goodsImg"
              effect="light"
              trigger="hover"
              placement="top"
              width="320px"
            >
              <template #default>
                <el-image
                  style="width: 300px; height: 300px"
                  :src="row.goodsImg"
                  fit="scale-down"
                ></el-image>
              </template>
              <template #reference>
                <el-image
                  style="height: 50px"
                  :src="row.goodsImg"
                  fit="scale-down"
                  :preview-src-list="[row.goodsImg]"
                ></el-image>
              </template>
            </el-popover>

            <el-image
              v-else
              :src="row.goodsImg"
              class="table-image"
              style="width: 50px; height: 50px"
            >
              <div slot="error" class="image-slot">
                <i
                  style="font-size: 50px !important"
                  class="el-icon-picture-outline"
                ></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="barNo" label="条形码" />
        <el-table-column
          show-overflow-tooltip
          prop="goodsNum"
          label="商品库存"
        />
        <el-table-column
          show-overflow-tooltip
          prop="clazzName1"
          label="商品一级分类"
          min-width="100"
        />
        <el-table-column
          show-overflow-tooltip
          prop="clazzName2"
          label="商品二级分类"
          min-width="100"
        />
        <el-table-column show-overflow-tooltip prop="brandName" label="品牌" />
        <el-table-column
          show-overflow-tooltip
          prop="groupName"
          label="仓库归属"
        />
        <el-table-column show-overflow-tooltip prop="state" label="商品状态">
          <template slot-scope="{ row }">
            <span>{{ +row.state === 1 ? '正常' : '待上架' }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column show-overflow-tooltip prop="state" label="一件代发">
          <template slot-scope="{ row }">
            <span>{{ +row.oneForDistribution === 0 ? '支持' : '不支持' }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'supplierGoods:delete'"
              @click="delHandler(row.id)"
              type="text"
              size="mini"
              >删除</el-button
            >
            <el-button
              v-auth="'supplierGoods:update'"
              @click="editHandler(row.id)"
              type="text"
              size="mini"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <ImportModal
      v-if="visible"
      @getData="getData(true)"
      :visible.sync="visible"
      :current-item="currentItem"
    />

    <AddModal
      v-if="addVisible"
      @getData="getData"
      :visible.sync="addVisible"
      :current-item="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import ImportModal from './components/ImportModal.vue'
import AddModal from './components/AddModal.vue'
export default {
  mixins: [watchParamsGetData],

  data() {
    return {
      visible: false,
      addVisible: false,
      currentItem: null,
      loading: false,
      tableData: [],
      brandList: [],
      groupList: [],
      stateList: [
        {
          label: '正常',
          value: 1
        },
        {
          label: '待上架',
          value: 3
        }
      ],
      typeList1: [],
      typeList2: [],
      distributionList: [
        {
          label: '支持',
          value: '0'
        },
        {
          label: '不支持',
          value: '1'
        }
      ],
      multipleSelection: [],
      params: {
        limit: 20,
        page: 1,
        clazzId1: '',
        clazzId2: '',
        goodsName: '',
        groupName: '',
        goodsNo: '',
        goodsBrand: ''
        // state: ''
      },
      totalCount: 0
    }
  },
  components: {
    ImportModal,
    AddModal
  },
  created() {
    this.getTypeList()
    this.getBrandList()
    this.getGroupList()
    this.getData()
  },
  methods: {
    async getData(query) {
      try {
        this.loading = true

        let params = { ...this.params }

        if (query) {
          params.limit = 20
          params.page = 1
        }

        const res = await this.$api.productList.getProductList(params)

        if (res.page && res.page.list) {
          this.tableData = res.page.list.map(_ => {
            _.goodsImg = this.$utils.getImg(_.goodsImg)

            return _
          })
        }

        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    async getTypeList() {
      try {
        const res = await this.$api.classification.getClazzList({
          limit: 1000,
          page: 1
        })
        this.typeList1 = (res.clazzList.list || []).map(item => {
          return {
            label: item.clazzName,
            value: item.id
          }
        })

        // const res = await this.$api.productList.getTypeList()
        // this.typeList1 = (res.list || []).map(item => {
        //   return {
        //     label: item.clazzName,
        //     value: item.id
        //   }
        // })
      } catch (err) {}
    },

    async getBrandList() {
      try {
        const res = await this.$api.brandList.getGoodsBrandList({
          limit: 1000,
          page: 1
        })

        this.brandList = (res.brandList.list || []).map(item => {
          return {
            label: item.brandName,
            value: item.id
          }
        })

        // const res = await this.$api.productList.getBrandList()
        // this.brandList = (res.list || []).map(item => {
        //   return {
        //     label: item.brandName,
        //     value: item.id
        //   }
        // })
      } catch (err) {}
    },

    async getGroupList() {
      try {
        const res = await this.$api.groupList.getGroupAllList()
        this.groupList = (res.group || []).map(item => {
          return {
            label: item.groupName,
            value: item.id
          }
        })
      } catch (err) {}
    },

    async typeOneChange(val) {
      // this.params.clazz2 = []
      // if (!val) {
      //   this.clazz2 = []
      // } else {
      //   try {
      //     const res = await this.$api.clazzList.getClazzList({
      //       parentId: val,
      //       limit: 1000,
      //       page: 1
      //     })
      //     this.clazz2 = (res.secondaryClazzList.list || []).map(item => {
      //       return {
      //         label: item.clazzName,
      //         value: item.id
      //       }
      //     })
      //   } catch (err) {}
      // }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => _.id)
    },

    async delHandler(id) {
      try {
        await this.$confirm(
          id ? '是否要删除该商品？' : '是否要批量删除所选商品？',
          '温馨提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )

        try {
          this.loading = true

          await this.$api.productList.delProduct({
            goodsIdList: id ? [id] : this.multipleSelection
          })
          this.$message.success('删除成功')
          this.getData()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      }
    },

    async editHandler(id) {
      try {
        this.loading = true

        const res = await this.$api.productList.getGoodsInfo({ id })
        this.addVisible = true
        this.currentItem = res.goodsSpecial
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
