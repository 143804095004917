<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="!isCreated ? '编辑' : '新增'"
    :visible.sync="show"
    top="10px"
    width="1200px"
  >
    <div class="content">
      <el-form
        label-width="120px"
        :model="form"
        size="mini"
        :rules="rules"
        ref="ruleForm"
      >
        <div class="row">
          <div class="left">
            <el-form-item label="商品类型">
              <el-select v-model="form.goodsCategory" clearable>
                <el-option
                  v-for="item in goodsCategoryList"
                  v-bind:value="item.id"
                  :label="item.name"
                  :key="item.id"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="商品名称" prop="goodsName">
              <el-input v-model="form.goodsName" placeholder="请输入商品名称" />
            </el-form-item>

            <el-form-item label="条形码" prop="barNo">
              <el-input v-model="form.barNo" placeholder="请输入条形码" />
            </el-form-item>

            <el-form-item label="第三方系统编码" prop="specNo">
              <el-input
                v-model="form.specNo"
                placeholder="请输入第三方系统编码"
              />
            </el-form-item>

            <el-form-item label="采购单位" prop="purchaseUnit">
              <el-select
                v-model="form.purchaseUnit"
                clearable
                filterable
                default-first-option
                placeholder="请选择采购单位"
              >
                <el-option
                  v-for="item in units"
                  :value="item"
                  :key="item"
                  :label="item"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="采购规格" prop="purchaseSpecification">
              <el-input
                v-model="form.purchaseSpecification"
                placeholder="请输入采购规格"
              />
            </el-form-item>

            <el-form-item label="单品成本价" prop="supplyPrice">
              <el-input
                type="number"
                v-model="form.supplyPrice"
                placeholder="请输入单品成本价"
              />
            </el-form-item>

            <el-form-item
              label="颜色"
              prop="goodsColor"
              v-if="+form.goodsCategory == 1"
            >
              <el-input v-model="form.goodsColor" placeholder="请输入颜色" />
            </el-form-item>

            <el-form-item label="规格换算比例" prop="conversionRatio">
              <div class="conversionRatioWrap">
                采购规格
                <el-input
                  style="width: 40px"
                  v-model="procurementSpecifications"
                  disabled
                ></el-input>
                &nbsp;：零售规格
                <el-input
                  style="width: 120px"
                  @input="
                    val => {
                      inputHandler(val, 'conversionRatio')
                    }
                  "
                  v-model="form.conversionRatio"
                  placeholder="零售规格数量"
                />
              </div>
              <div style="font-size: 12px; color: #999">
                门店购买后，根据换算比例，将采购规格换算为零售规格加入到门店的库存
              </div>
            </el-form-item>
          </div>

          <div class="right">
            <!-- <el-form-item label="2" style="opacity: 0"> </el-form-item> -->
            <el-form-item label="一级分类" prop="clazzId1">
              <el-select
                v-model="form.clazzId1"
                @change="typeOneChange"
                clearable
                filterable
                default-first-option
              >
                <el-option
                  v-for="item in clazz"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="二级分类" prop="clazzId2">
              <el-select v-model="form.clazzId2" clearable>
                <el-option
                  v-for="item in clazz2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="所属仓库" prop="groupId">
              <el-select v-model="form.groupId" clearable>
                <el-option
                  v-for="item in groupList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              label="自编码"
              v-if="+form.goodsCategory == 2"
              prop="selfCoding"
            >
              <el-input
                maxlength="5"
                @input="
                  val => {
                    inputHandler(val, 'selfCoding')
                  }
                "
                v-model="form.selfCoding"
                placeholder="请输入自编码"
              />
            </el-form-item>
            <el-form-item label="自编码" v-else>
              <el-input
                maxlength="5"
                @input="
                  val => {
                    inputHandler(val, 'selfCoding')
                  }
                "
                v-model="form.selfCoding"
                placeholder="请输入自编码"
              />
            </el-form-item>
            <el-form-item label="品牌" prop="goodsBrand">
              <el-select
                v-model="form.goodsBrand"
                clearable
                filterable
                default-first-option
                placeholder="请选择品牌"
              >
                <el-option
                  v-for="item in brands"
                  v-bind:value="item.value"
                  :label="item.label"
                  :key="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="零售单位" prop="unit">
              <el-select
                v-model="form.unit"
                clearable
                filterable
                default-first-option
                placeholder="请选择零售单位"
              >
                <el-option
                  v-for="item in units"
                  :value="item"
                  :key="item"
                  :label="item"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="零售规格" prop="specification">
              <el-input
                v-model="form.specification"
                placeholder="请输入零售规格"
              />
            </el-form-item>
            <el-form-item
              :label="
                +form.goodsCategory !== 2
                  ? '建议零售价(元)'
                  : '建议零售价(元/kg)'
              "
              prop="suggestedRetailPrice"
            >
              <el-input
                type="number"
                v-model="form.suggestedRetailPrice"
                placeholder="保留2位小数:1.23"
              />
            </el-form-item>
            <el-form-item
              label="尺码"
              prop="goodsSize"
              v-if="+form.goodsCategory == 1"
            >
              <el-input v-model="form.goodsSize" placeholder="请输入尺码" />
            </el-form-item>
            <el-form-item label="结算时修改价格" prop="goodsType">
              <el-radio-group v-model="form.goodsType">
                <el-radio label="2">是</el-radio>
                <el-radio label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>

        <el-form-item
          style="margin-bottom: 8px"
          label="商品图片"
          prop="goodsImg"
        >
          <el-upload
            class="avatar-uploader"
            :action="'/purchase/fileUpload/upload?token=' + token"
            :multiple="false"
            :show-file-list="false"
            :on-success="res => fileChange('goodsImg', res)"
          >
            <img
              v-if="form.goodsImg"
              :src="
                BASE_URL +
                `/purchase/fileUpload/getImg?fdfsId=${form.goodsImg}&token=${token}`
              "
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>

            <template slot="tip">
              <div class="el-upload__tip">最佳尺寸400px*400px</div>
            </template>
          </el-upload>
        </el-form-item>

        <el-form-item
          style="margin-bottom: 8px"
          label="微店详情图"
          prop="goodsDetailImg"
        >
          <el-upload
            class="avatar-uploader"
            :action="'/purchase/fileUpload/upload?token=' + token"
            :multiple="false"
            :show-file-list="false"
            :on-success="res => fileChange('goodsDetailImg', res)"
          >
            <img
              v-if="form.goodsDetailImg"
              :src="
                BASE_URL +
                `/purchase/fileUpload/getImg?fdfsId=${form.goodsDetailImg}&token=${token}`
              "
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>

            <template #tip>
              <div class="el-upload__tip">最佳尺寸750px*不限</div>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" :loading="loading" @click="submit"
        >保存</el-button
      >
    </span>

    <el-dialog
      custom-class="bigImgDialog"
      append-to-body
      v-model="dialogVisible"
      :show-close="false"
    >
      <el-image style="width: 100%" :src="dialogImageUrl"></el-image>
    </el-dialog>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import { BASE_URL } from '@/constant'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      BASE_URL,
      rules: {
        purchaseUnit: [
          { required: true, message: '请选择采购单位', trriger: 'change' }
        ],
        purchaseSpecification: [{ required: true, message: '请输入采购规格' }],
        supplyPrice: [{ required: true, message: '请输入单品成本价' }],
        unit: [{ required: true, message: '请选择零售单位' }],
        specification: [{ required: true, message: '请输入零售规格' }],
        conversionRatio: [{ required: true, message: '请输入规格换算比例' }],
        barNo: [{ required: true, message: '请输入条形码' }],
        goodsName: [{ required: true, message: '请输入商品名称' }],
        suggestedRetailPrice: [{ required: true, message: '请输入建议零售价' }],
        clazzId1: [
          {
            required: true,
            message: '请选择一级分类'
          }
        ],
        selfCoding: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) return callback(new Error('请输入自编码'))

              if (value.length < 5) {
                return callback(new Error('自编码由5位数字组成'))
              }
              callback()
            }
          }
        ]
      },
      procurementSpecifications: 1,
      form: {
        conversionRatio: '',
        goodsCategory: 1,
        oneForDistribution: '1',
        goodsImg: '',
        selfCoding: '',
        goodsDetailImg: '',
        // goodsDetailImg: [],
        goodsType: '1'
      },
      fileList: [],
      brands: [],
      groupList: [],
      clazz: [],
      clazz2: [],
      file: null,
      loading: false,
      dialogVisible: false,
      dialogImageUrl: '',
      params: {},
      goodsCategoryList: [
        {
          name: '标准商品',
          id: 1
        },
        {
          name: '称重商品',
          id: 2
        }
      ],
      units: [
        '份',
        '包',
        '听',
        '卷',
        '双',
        '台',
        '个',
        '块',
        '套',
        '把',
        '支',
        '条',
        '板',
        '桶',
        '片',
        '版',
        '瓶',
        '盒',
        '筒',
        '箱',
        '组',
        '罐',
        '袋',
        '辆',
        '件'
      ]
    }
  },
  computed: {
    token() {
      return localStorage.getItem('token')
    }
  },
  created() {
    this.getBrandList()
    this.getTypeList()
    this.getGroupList()
    if (this.currentItem) {
      const r = { ...this.currentItem }

      r.goodsCategory = r.goodsCategory ? Number(r.goodsCategory) : 1

      this.form = r

      this.typeOneChange(this.form.clazzId1)

      // //支持多图
      // this.fileList = (r.goodsDetailImg || []).map((item) => ({
      //   id: item.url,
      //   url: config.baseUrl + `/purchase/fileUpload/getImg?fdfsId=${item.url}&token=${token.value}`
      // }));
    }
  },
  methods: {
    async getGroupList() {
      try {
        const res = await this.$api.groupList.getGroupAllList()
        this.groupList = (res.group || []).map(item => {
          return {
            label: item.groupName,
            value: item.id
          }
        })
      } catch (err) {}
    },
    async typeOneChange(val) {
      this.params.clazz2 = []
      if (!val) {
        this.clazz2 = []
      } else {
        try {
          const res = await this.$api.clazzList.getClazzList({
            parentId: val,
            limit: 1000,
            page: 1
          })
          this.clazz2 = (res.secondaryClazzList.list || []).map(item => {
            return {
              label: item.clazzName,
              value: item.id
            }
          })
        } catch (err) {}
      }
    },
    async getTypeList() {
      try {
        const res = await this.$api.classification.getClazzList({
          limit: 1000,
          page: 1
        })
        this.clazz = (res.clazzList.list || []).map(item => {
          return {
            label: item.clazzName,
            value: item.id
          }
        })
      } catch (err) {}
    },

    async getBrandList() {
      try {
        const res = await this.$api.brandList.getGoodsBrandList({
          limit: 1000,
          page: 1
        })

        this.brands = (res.brandList.list || []).map(item => {
          return {
            label: item.brandName,
            value: item.id
          }
        })

        // const res = await this.$api.productList.getBrandList()
        // this.brandList = (res.list || []).map(item => {
        //   return {
        //     label: item.brandName,
        //     value: item.id
        //   }
        // })
      } catch (err) {}
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    fileChange(key, response) {
      this.form[key] = response.url
    },

    successHandler(res) {
      this.form.goodsDetailImg.push(res.url)
    },

    handleRemove(file, fileList) {
      this.form.goodsDetailImg = fileList.map(
        item => item.response.url || item.url
      )
    },

    inputHandler(val, key) {
      this.form[key] = this.form[key].replace(/[^0-9]/g, '')
    },

    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.loading = true
          try {
            const params = { ...this.form }
            params.sellPrice = params.suggestedRetailPrice

            if (!this.isCreated) {
              await this.$api.productList.updateGoodsInfo(params)
              this.$message.success('编辑成功')
            } else {
              await this.$api.productList.saveGoods(params)
              this.$message.success('添加成功')
            }
            this.show = false
            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.row {
  display: flex;
  .left,
  .right {
    flex: 1;
  }
  .right {
    margin-left: 40px;
  }
}
::v-deep .el-select {
  width: 100%;
}

::v-deep .el-upload--picture-card,
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 98px;
  height: 98px;
  line-height: 98px;
}
</style>
